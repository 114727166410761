.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

header, footer {
  text-align: center;
  padding: 20px;
  font-family: "syne", sans-serif;
}

#hero {
  background-color: #e0f2f1;
  padding: 50px 20px;
  text-align: center;
}

.circle-nav {
  display: flex;
  justify-content: center;
  gap: 10vw; /* Space between circles */
  flex-wrap: wrap;
}

h2 {
  font-family: "Syne", sans-serif;
}

.nav-circle {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items vertically */
  text-decoration: none; /* Remove underline from links */
  width: 12vw; /* Adjust based on your design */
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: none;
  background: none;
  font-family: "space grotesk", sans-serif;
}

.nav-circle img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
}

.circle-caption {
  display: block;
  margin-top: 8px;
}

.info-blurb {
  position: absolute;
  width: 30vw; 
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  top: 100%; 
  left: 50%;
  transform: translateX(-50%); 
  z-index: 100; 
  display: none; 
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.nav-circle:hover .info-blurb {
  display: block;
}

html {
  scroll-behavior: smooth;
}

.modal {
  font-size: 16px;
  padding: 20px;
  background: white;
  border-radius: 5px;
  font-family: "space grotesk", sans-serif;
}

/* Style for the main content area */
/* Ensures the grid layout works as expected */
.main-grid-container {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr; /* Side columns are 1fr, center column is 3fr */
  gap: 20px; /* Space between columns */ /* Adjust based on your design preference */
  margin: 0 auto; /* Center the grid in the middle of the page */
  padding: 20px;
}

/* Main content in the center column */
.main-content {
  font-family: 'Space Grotesk', sans-serif;
  grid-column: 2 / 3; /* Place main content in the middle column */
  font-size: 20px;
}

/* Style for the side columns */
.side-column {
  background-color: #f4f4f4; /* Example background color */
  padding: 20px;
}

.fun-fact-box {
  border: 1px solid #ccc; /* Light grey border */
  padding: 20px;
  background-color: #f9f9f9; /* Light background for the box */
  box-shadow: 0 2px 5px rgba(0,0,0,0.1); /* Subtle shadow for depth */
}

.header {
  font-family: "syne", sans-serif;
  /* bold */
  font-weight: bold;
  font-size: 24px;
}



.nav-circle img {
  border-radius: 50%; /* Makes the image circular */
  width: 300px; /* Diameter of the circle */
  height: 300px; /* Diameter of the circle */
  object-fit: cover; /* Ensures the image covers the circle nicely */
}

.circle-caption {
  margin-top: 8px; /* Space between the circle and the caption */
  font-size: 14px; /* Caption font size */
  text-align: center; /* Centers the caption text */
  color: #333; /* Caption text color */
}


.nav-circle:hover{
  transform: scale(1.1); /* Zoom effect on hover */
}

.question {
  font-weight: bold;
}



footer {
  background-color: #004d40;
  color: white;
}

.sticky-header {
  position: sticky;
  top: 0; /* Ensures the header stays at the top of the viewport */
  background-color: #86CD82; /* Or any other color fitting your design */
  z-index: 1000; /* Keeps the header above other content */
  width: 100%; /* Ensures the header extends full width */
  padding: 10px 20px; /* Adds some padding inside the header */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Optional: adds shadow for better visibility */
}

/* Ensuring the navigation within the header is styled appropriately */
nav {
  display: inline-block; /* Ensures the navigation links are in line */
}

nav a {
  text-decoration: none;
  margin: 0 10px;
  color: #333; /* Or your choice of color */
  font-weight: bold; /* Makes the navigation links bold */
}

nav a:hover {
  text-decoration: underline; /* Adds an underline on hover to indicate clickable */
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* or any specific size */
  height: auto; /* or any specific size, keeping it responsive */
  overflow: hidden; /* optional, to hide parts of the image if it overflows */
}

.image-container img {
  width: auto; /* or any specific size */
  height: auto; /* or any specific size, keeping it responsive */
  max-width: 50%; /* removes bottom space/gap */
  object-fit: contain; /* Ensures the aspect ratio is maintained without cropping */
}

* {
  box-sizing: border-box;
}
