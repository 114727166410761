.fun-facts-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Creates as many columns as can fit, each at least 250px wide */
    gap: 20px; /* Space between columns */
    padding: 20px;
    width: 95vw;
    box-sizing: border-box;
    justify-content: center;
    margin: 0 auto;
  }
  
  .fun-fact-box {
    background-color: #f3e3c1;
    border: 8px solid #86CD86;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  }
  
  .fun-fact-link {
    color: inherit;
  }
  
  .fun-fact-link:hover {
    text-decoration: underline;
  }
  